html, body {
  height: 100%;
  background-color: black;
}

#root {
  height: 100%;
}

.mb-2 {
  margin-bottom: 20px;
}

.modal-backdrop {
  background-color: #00000000;
}

.modal-body, .modal-header {
  background-color: #D3D3D3;
}

.outerDiv {
  width: 140px;
  height: 100px;
  background-color: red;
  cursor: pointer;
}

.innerDiv {
  width: 140px;
  background-color: black;
  font-size: 18px;
  color: white;
  overflow: hidden;
}

.container {
  height: 100%;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.maintext {
  word-wrap: 'break-word';
  width: 'fit-content';
  max-width: "98vw";
  position: 'relative';
}